<template>
  <v-container>
    <v-card class="px-5 py-3">
      <v-data-table
        :headers="headers"
        :items="items"
        :single-select="true"
        item-key="id"
        loading-text="Идёт загрузка данных..."
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItems"
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="6" sm="3" md="3" lg="3" xl="3">
              <v-menu
                ref="from"
                v-model="fromDateDialog"
                :close-on-content-click="false"
                :return-value.sync="from"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedFrom"
                    label="С:"
                    readonly
                    v-bind="attrs"
                    prepend-icon="mdi-calendar-blank-multiple"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="from"
                  no-title
                  scrollable
                  locale="ru"
                  :first-day-of-week="1"
                >
                  <v-spacer />
                  <v-btn text color="primary" @click="fromDateDialog = false">
                    Отмена
                  </v-btn>
                  <v-btn text color="primary" @click="saveFrom">
                    Применить
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="3" md="3" lg="3" xl="3">
              <v-menu
                ref="to"
                v-model="toDateDialog"
                :close-on-content-click="false"
                :return-value.sync="to"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedTo"
                    label="По:"
                    readonly
                    v-bind="attrs"
                    prepend-icon="mdi-calendar-blank-multiple"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="to"
                  no-title
                  scrollable
                  locale="ru"
                  :first-day-of-week="1"
                >
                  <v-spacer />
                  <v-btn text color="primary" @click="toDateDialog = false">
                    Отмена
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      loadRow();
                      toDateDialog = false;
                      $refs.to.save(to);
                    "
                  >
                    Применить
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-select
                v-model="type"
                label="Тип статуса"
                :items="types"
                @change="selectFilter"
              />
            </v-col>
            <v-col>
              <v-select
                v-model="order_by"
                label="Сортировать"
                :items="typesSort"
                @change="selectFilter"
              />
            </v-col>
            <v-col>
              <v-checkbox
                v-model="my_tasks"
                @change="loadRow"
                label="Только мои"
              ></v-checkbox>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.is_done`]="{ item }">
          <v-row align="center">
            <v-checkbox
              v-model="item.is_done"
              @change="taskState(item.id, item.is_done)"
              :disabled="disabledBtn"
            />
            <v-btn icon @click="deleteTask(item.id)">
              <v-icon small color="red darken-2"> mdi-delete </v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import { BookingApiUrls } from "@/services/accommodationRequests/booking.api.js";

export default {
  name: "Tasks",
  data: () => ({
    disabledBtn: false,
    firstWatcherTrigger: false,
    totalItems: 0,
    my_tasks: null,
    options: {},
    loading: true,
    from: null, // new Date().toISOString().substr(0, 10),
    to: null, // new Date(moment(new Date()).add(1, "day")).toISOString().substr(0, 10),
    fromDateDialog: false,
    toDateDialog: false,
    headers: [
      {
        text: "Комментарии",
        value: "description",
        sortable: false,
        class: "table-title"
      },
      {
        text: "Дата",
        value: "todo_date",
        sortable: false,
        class: "table-title"
      },
      {
        text: "Время",
        value: "todo_time",
        sortable: false,
        class: "table-title"
      },
      {
        text: "Гость",
        value: "full_name",
        sortable: false,
        class: "table-title"
      },
      {
        text: "Ответственный",
        value: "full_name",
        sortable: false,
        class: "table-title"
      },
      {
        text: "Действия",
        value: "title",
        sortable: true,
        class: "table-title"
      },
      {
        text: "Состояние",
        value: "state",
        sortable: false,
        class: "table-title"
      },
      {
        text: "Статус",
        value: "is_done",
        sortable: false,
        class: "table-title"
      }
    ],
    type: null,
    types: [
      { text: "Все", value: "all", class: "table-title" },
      { text: "Выполненные", value: "done", class: "table-title" },
      { text: "Будущие", value: "future", class: "table-title" },
      { text: "Просроченные", value: "overdue", class: "table-title" }
    ],
    order_by: null,
    typesSort: [
      { text: "Сначала старые", value: "due_date", class: "table-title" },
      { text: "Сначала новые", value: "-due_date", class: "table-title" }
    ],
    items: []
  }),
  computed: {
    computedFrom() {
      if (this.from) return moment(this.from).format("DD.MM.YYYY");
      return null;
    },
    computedTo() {
      if (this.to) return moment(this.to).format("DD.MM.YYYY");
      return null;
    }
  },
  watch: {
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        this.loadRow();
      },
      deep: true
    }
  },
  created() {
    this.loadRow();
  },
  methods: {
    saveFrom() {
      this.loadRow();
      this.fromDateDialog = false;
      this.$refs.from.save(this.from);
    },
    selectFilter() {
      this.options.page = 1;
      this.loadRow();
    },
    async taskState(id, value) {
      this.disabledBtn = true;
      await BookingApiUrls.setTask({ is_done: value }, id);
      this.disabledBtn = false;
      this.loadRow();
      this.$store.commit("SET_TRIGGER_CHECK_NOTIFICATIONS_LOAD"); // обновить таски сверху справа
    },
    async deleteTask(id) {
      this.items = [];
      await BookingApiUrls.deleteTask(id);
      this.loadRow();
    },
    async loadRow() {
      this.items = [];
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10,
        start: this.from ? new Date(this.from).getTime() / 1000 : null,
        end: this.to ? new Date(this.to).getTime() / 1000 : null,
        status: this.type ? this.type : null,
        order_by: this.order_by ? this.order_by : null,
        my_tasks: this.my_tasks || null
      };
      this.loading = true;
      let response = await BookingApiUrls.getTasks(params);
      this.totalItems = response.data.meta.pagination.count;
      this.initialize(response.data.results);
      this.items = response.data.results;
    },
    initialize(dataSet) {
      dataSet.forEach(element => {
        element.todo_date = moment(
          element.due_date.toString().length == 13
            ? element.due_date
            : element.due_date * 1000
        ).format("DD.MM.YYYY");
        element.todo_time = moment(element.due_date * 1000).format("HH:mm");
        element.full_name = `${element.responsible?.first_name || ""} ${element
          .responsible?.last_name || ""} ${element.responsible?.middle_name ||
          ""}`;
        element.full_name = element.full_name.includes("undefined")
          ? "-"
          : element.full_name;
      });
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
